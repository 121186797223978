import React from 'react';

import ServicesPage from './../../components/ServicesPage';
import { TextSection, SubHeading, Declaration, UL, LI } from '../../components/textComponents';

import erosionControlPhoto0 from '../../../static/assets/lake_erosion_left.jpg';

import erosionControlPhoto1 from '../../../static/assets/20150926_102255_resized.jpg';

import erosionControlPhoto2 from '../../../static/assets/erosion_control_yard_inprogress.jpg';

import erosionControlPhoto3 from '../../../static/assets/erosion_control_rotated.jpg';

import erosionControlPhoto4 from '../../../static/assets/lake_erosion_inprogress.jpg';

import erosionControlPhoto8 from '../../../static/assets/lake_erosion_wide.jpg';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Northeast Ohio',
  'Erosion',
  'Erosion Control',
];

const page = {
  title: 'Erosion Control',
  keywords: keywords,
  description: 'VLP Landscaping specializes in installing living walls and stabilizing blocks to control erosion on your property\'s steep inclines and banks.',
};

const heading = {
  title: page.title,
  img: erosionControlPhoto0,
  alt: 'Bank stabilization along edge of a pond.',
};

export default () => (
  <ServicesPage page={page} heading={heading}>
    <TextSection>
      <Declaration>
        VLP Landscaping is an erosion control company that specializes in installing retaining living walls. The types of walls and services we provide include:
      </Declaration>
      <UL>
        <LI>EarthBloxx</LI>
        <LI>EnviroBloxx</LI>
        <LI>GreenLoxx</LI>
        <LI>Trinity Wire wall</LI>
        <LI>Bank Stabilization</LI>
      </UL>
    </TextSection>
    <TextSection css={`
      margin-top: 5rem;
    `}>
      <SubHeading>Photos from some of our projects:</SubHeading>
      <img src={erosionControlPhoto3} alt="Bank stabilization blocks installed on a steep hill"/>
      <img src={erosionControlPhoto2} alt="Stabilized steep hill covered in grass"/>
      <img src={erosionControlPhoto1} alt="Two workers installing stabilization blocks on a steep incline"/>
      <img src={erosionControlPhoto4} alt="Bank stabilization in progress around pond"/>
      <img src={erosionControlPhoto8} alt="Bank stabilization blocks installed around pond"/>
    </TextSection>
  </ServicesPage>
);
